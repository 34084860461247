import React, { useState, useEffect } from 'react';
import './QuestionSettingsCardComponent.css';
import ApiService from "../../services/ApiService";

const QuestionSettingsCardComponent = ({ id, onGenerate }) => {
    const grades = ['Grade 1', 'Grade 2', 'Grade 3', 'Grade 4', 'Grade 5', 'Grade 6', 'Grade 7', 'Grade 8'];
    const strands = ['Number', 'Algebra', 'Data', 'Spatial Sense', 'Financial Literacy'];

    // State variables
    const [selectedGrade, setSelectedGrade] = useState('');
    const [selectedStrand, setSelectedStrand] = useState('');
    const [chapters, setChapters] = useState([]);
    const [selectedChapter, setSelectedChapter] = useState('');
    const [topics, setTopics] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState('');

    // Fetch chapters when grade or strand changes
    useEffect(() => {
        const fetchChapters = async () => {
            if (selectedGrade && selectedStrand) {
                try {
                    const requestBody = {
                        gradeIndex: grades.indexOf(selectedGrade),
                        strandIndex: strands.indexOf(selectedStrand),
                    };

                    const response = await ApiService.post('/get-chapters', requestBody);
                    if (response.status === 200) {
                        setChapters(response.data.chapters);
                        setTopics([]);
                        setSelectedChapter('');
                        setSelectedTopic('');
                    }
                } catch (error) {
                    console.error('Error fetching chapters:', error);
                    setChapters([]);
                    setTopics([]);
                    setSelectedChapter('');
                    setSelectedTopic('');
                }
            }
        };

        fetchChapters();
    }, [selectedGrade, selectedStrand]);

    // Fetch topics when the selectedChapter changes (but only if it's not reset to '')
    useEffect(() => {
        const fetchTopics = async () => {
            if (selectedChapter && selectedGrade && selectedStrand) {
                try {
                    const requestBody = {
                        gradeIndex: grades.indexOf(selectedGrade),
                        strandIndex: strands.indexOf(selectedStrand),
                        chapter: selectedChapter
                    };

                    const response = await ApiService.post('/get-topics', requestBody);
                    if (response.status === 200) {
                        setTopics(response.data.topics);
                        setSelectedTopic('');
                    }
                } catch (error) {
                    console.error('Error fetching topics:', error);
                    setTopics([]);
                    setSelectedTopic('');
                }
            }
        };

        if (selectedChapter !== '') {
            fetchTopics();
        }
    }, [selectedChapter, selectedGrade, selectedStrand]);

    const handleGenerate = () => {
        if (onGenerate) {
            const requestBody = {
                gradeIndex: grades.indexOf(selectedGrade),
                strandIndex: strands.indexOf(selectedStrand),
                chapter: selectedChapter,
                topic: selectedTopic
            };
            onGenerate(id, requestBody);
        }
    };

    return (
        <div className="card-container">
            <h2>Question Settings</h2>

            {/* Group for Grade, Strand, and Chapter */}
            <div className="horizontal-group">
                <div className="vertical-group">
                    <label className="card-label">Grade: </label>
                    <select className="card-select" value={selectedGrade}
                            onChange={(e) => setSelectedGrade(e.target.value)}>
                        <option value="">Select Grade</option>
                        {grades.map((grade) => (
                            <option key={grade} value={grade}>{grade}</option>
                        ))}
                    </select>
                </div>

                <div className="vertical-group">
                    <label className="card-label">Strand: </label>
                    <select className="card-select" value={selectedStrand}
                            onChange={(e) => setSelectedStrand(e.target.value)}>
                        <option value="">Select Strand</option>
                        {strands.map((strand) => (
                            <option key={strand} value={strand}>{strand}</option>
                        ))}
                    </select>
                </div>

                <div className="vertical-group">
                    <label className="card-label">Chapter: </label>
                    <select className="card-select" value={selectedChapter}
                            onChange={(e) => setSelectedChapter(e.target.value)}>
                        <option value="">Select Chapter</option>
                        {chapters.map((chapter) => (
                            <option key={chapter} value={chapter}>{chapter}</option>
                        ))}
                    </select>
                </div>
            </div>

            {/* Dropdown for Topic */}
            <div className="horizontal-group">
                <div className="vertical-group">
                    <label className="card-label">Topic: </label>
                    <select className="card-select" value={selectedTopic}
                            onChange={(e) => setSelectedTopic(e.target.value)}>
                        <option value="">Select Topic</option>
                        {topics.map((topic, index) => (
                            <option key={index} value={topic}>{topic}</option>
                        ))}
                    </select>
                </div>
            </div>

            <button className="card-button" onClick={handleGenerate}>Generate</button>
        </div>
    );

};

export default QuestionSettingsCardComponent;
