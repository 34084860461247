import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, MenuItem, Select, Checkbox, List, ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material';

const ExploreSettingsComponent = ({ isOpen, onClose, onSubmit }) => {
    const [selectedCategory, setSelectedCategory] = useState('');
    const [subcategories, setSubcategories] = useState([]);
    const [customTitle, setCustomTitle] = useState('');
    const [customDescription, setCustomDescription] = useState('');
    const [newSubcategory, setNewSubcategory] = useState('');
    const [customGeneratedSubcategories, setCustomGeneratedSubcategories] = useState([]);

    const categories = [
        { tag: 'Difficulty', explanation: 'Modify the numbers', subcategories: ['easy', 'medium', 'hard'] },
        { tag: 'Noise', explanation: 'Extra information in the question', subcategories: ['low', 'medium', 'high'] },
    ];

    // Handle dropdown category selection
    const handleCategoryChange = (event) => {
        const category = categories.find((cat) => cat.tag === event.target.value);
        setSelectedCategory(event.target.value);
        setSubcategories(category ? category.subcategories.map((sub) => ({ name: sub, checked: true })) : []);
    };

    // Handle checkbox toggle
    const handleCheckboxChange = (index) => {
        const updatedSubcategories = [...subcategories];
        updatedSubcategories[index].checked = !updatedSubcategories[index].checked;
        setSubcategories(updatedSubcategories);
    };

    // Add a new subcategory
    const handleAddSubcategory = () => {
        if (newSubcategory.trim()) {
            setSubcategories([...subcategories, { name: newSubcategory, checked: true }]);
            setNewSubcategory('');
        }
    };

    // Generate subcategories for custom exploration
    const handleGenerateSubcategories = async () => {
        try {
            // Simulate backend call
            const response = await fetch('/api/generate-subcategories', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ title: customTitle, description: customDescription }),
            });
            const data = await response.json();
            setCustomGeneratedSubcategories(data.subcategories.map((sub) => ({ name: sub, checked: true })));
        } catch (error) {
            console.error('Error generating subcategories:', error);
        }
    };

    // Handle form submission
    const handleSubmit = () => {
        const selectedSubcategories = subcategories.filter((sub) => sub.checked).map((sub) => sub.name);
        const explorationParams = {
            exploreCategory: selectedCategory,
            subCategories: selectedSubcategories,
            customExploration: selectedCategory === 'custom exploration' ? { title: customTitle, description: customDescription, generatedSubcategories: customGeneratedSubcategories } : null,
        };
        onSubmit(explorationParams);
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Explore Node</DialogTitle>
            <DialogContent>
                {/* Category Dropdown */}
                <Select
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    displayEmpty
                    fullWidth
                    margin="dense"
                >
                    <MenuItem value="" disabled>
                        Select Exploration Category
                    </MenuItem>
                    {categories.map((category) => (
                        <MenuItem key={category.tag} value={category.tag}>
                            {category.tag} - {category.explanation}
                        </MenuItem>
                    ))}
                    <MenuItem value="custom exploration">Custom Exploration</MenuItem>
                </Select>

                {/* Subcategories List */}
                {selectedCategory !== 'custom exploration' && (
                    <List>
                        {subcategories.map((sub, index) => (
                            <ListItem key={index}>
                                <ListItemText primary={sub.name} />
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        checked={sub.checked}
                                        onChange={() => handleCheckboxChange(index)}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                        <ListItem>
                            <TextField
                                label="New Subcategory"
                                fullWidth
                                margin="dense"
                                value={newSubcategory}
                                onChange={(e) => setNewSubcategory(e.target.value)}
                            />
                            <Button onClick={handleAddSubcategory}>Add</Button>
                        </ListItem>
                    </List>
                )}

                {/* Custom Exploration Fields */}
                {selectedCategory === 'custom exploration' && (
                    <>
                        <TextField
                            label="Title"
                            fullWidth
                            margin="dense"
                            value={customTitle}
                            onChange={(e) => setCustomTitle(e.target.value)}
                        />
                        <TextField
                            label="Description"
                            fullWidth
                            margin="dense"
                            value={customDescription}
                            onChange={(e) => setCustomDescription(e.target.value)}
                        />
                        <Button onClick={handleGenerateSubcategories} variant="outlined">
                            Generate Subcategories
                        </Button>
                        <ListItem>
                            <TextField
                                label="New Subcategory"
                                fullWidth
                                margin="dense"
                                value={newSubcategory}
                                onChange={(e) => setNewSubcategory(e.target.value)}
                            />
                            <Button onClick={handleAddSubcategory}>Add</Button>
                        </ListItem>
                        {customGeneratedSubcategories.length > 0 && (
                            <List>
                                {customGeneratedSubcategories.map((sub, index) => (
                                    <ListItem key={index}>
                                        <ListItemText primary={sub.name} />
                                        <ListItemSecondaryAction>
                                            <Checkbox
                                                checked={sub.checked}
                                                onChange={() => handleCheckboxChange(index)}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSubmit} variant="contained">
                    Explore
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExploreSettingsComponent;
