import React, { useState } from 'react';
import { Box, Card, CardContent, IconButton, Typography, Stack, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import EditQuestionComponent from "./EditQuestionComponent";
import CloseIcon from '@mui/icons-material/Close';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const ItemType = 'CARD';

const DraggableCard = ({ question, index, moveCard, handleOpenRemoveDialog, handleOpenEditDialog, handleSpaceChange, setWorksheetQuestions }) => {
    const [{ isDragging }, dragRef] = useDrag({
        type: ItemType,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, dropRef] = useDrop({
        accept: ItemType,
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveCard(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    return (
        <Card
            ref={(node) => dragRef(dropRef(node))}
            sx={{ width: '100%', maxWidth: 600, mb: 2, opacity: isDragging ? 0.5 : 1 }}
        >
            <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                        <DragHandleIcon sx={{ mr: 2 }} /> {/* Drag Handle Icon */}
                        <Typography variant="h6">{question.text}</Typography>
                    </Box>
                    <IconButton onClick={() => handleOpenRemoveDialog(question.uid)}>
                        <CloseIcon color="black" />
                    </IconButton>
                </Box>
                <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
                    <TextField
                        type="number"
                        label="Spacing"
                        variant="outlined"
                        size="small"
                        defaultValue={1}
                        InputProps={{
                            inputProps: {
                                min: 1,
                            },
                        }}
                        onChange={(e) => handleSpaceChange(question.uid, e.target.value)}
                        sx={{ width: '30%' }}
                    />
                    <Box>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            sx={{ mr: 1 }}
                            onClick={() => handleOpenEditDialog(question)}
                        >
                            Edit
                        </Button>
                        <Button variant="contained" color="primary" size="small">
                            Add Media
                        </Button>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

const WorksheetManagerComponent = (props) => {
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [questionToRemove, setQuestionToRemove] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState(null);

    const handleRemoveDialogOpen = (uid) => {
        setQuestionToRemove(uid);
        setRemoveDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setRemoveDialogOpen(false);
        setQuestionToRemove(null);
    };

    const handleOpenEditDialog = (question) => {
        setSelectedQuestion(question);
        setEditDialogOpen(true);
    };

    const handleCloseEditDialog = () => {
        setEditDialogOpen(false);
        setSelectedQuestion(null);
    };

    const handleRemoveQuestion = () => {
        props.setWorksheetQuestions((prev) => prev.filter((question) => question.uid !== questionToRemove));
        handleCloseDialog();
    };

    const handleEditComplete = (updatedText) => {
        props.setWorksheetQuestions((prev) =>
            prev.map((q) => (q.uid === selectedQuestion.uid ? { ...q, text: updatedText } : q))
        );
        handleCloseEditDialog();
    };

    const handleSendPrompt = (prompt) => {
        console.log('Prompt sent to backend:', prompt);
        // Add backend integration here
    };

    const handleSpaceChange = (uid, spacing) => {
        spacing = parseInt(spacing);
        props.setWorksheetQuestions((prev) =>
            prev.map((q) => (q.uid === uid ? { ...q, spacing } : q))
        );
    };

    const moveCard = (fromIndex, toIndex) => {
        props.setWorksheetQuestions((prev) => {
            const updatedQuestions = Array.from(prev);
            const [movedItem] = updatedQuestions.splice(fromIndex, 1);
            updatedQuestions.splice(toIndex, 0, movedItem);
            return updatedQuestions;
        });
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Box display="flex" justifyContent="center" alignItems="flex-start" minHeight="100vh" bgcolor="#f5f5f5" margin={5}>
                <Stack direction="column" alignItems="center" spacing={2}>
                    <h1>Worksheet Manager</h1>
                    {props.worksheetQuestions?.length > 0 ? (
                        props.worksheetQuestions.map((question, index) => (
                            <DraggableCard
                                key={question.uid}
                                question={question}
                                index={index}
                                moveCard={moveCard}
                                handleOpenRemoveDialog={handleRemoveDialogOpen}
                                handleOpenEditDialog={handleOpenEditDialog}
                                handleSpaceChange={handleSpaceChange}
                                setWorksheetQuestions={props.setWorksheetQuestions}
                            />
                        ))
                    ) : (
                        <p>No questions added to the worksheet.</p>
                    )}
                </Stack>

                {/* Confirmation Dialog */}
                <Dialog open={removeDialogOpen} onClose={handleCloseDialog}>
                    <DialogTitle>Remove Question</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to remove this question?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleRemoveQuestion} color="error" autoFocus>
                            Remove
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Edit Question Dialog */}
                {selectedQuestion && (
                    <EditQuestionComponent
                        question={selectedQuestion}
                        isOpen={editDialogOpen}
                        onClose={handleCloseEditDialog}
                        onSendPrompt={handleSendPrompt}
                        onEditComplete={handleEditComplete}
                    />
                )}
            </Box>
        </DndProvider>
    );
};

export default WorksheetManagerComponent;
