import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Box, IconButton, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

const EditQuestionComponent = ({ node, isOpen, onClose, onSendPrompt, onEditComplete }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedText, setEditedText] = useState(node?.text || '');
    const [prompt, setPrompt] = useState('');

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSaveEdit = () => {
        setIsEditing(false);
        onEditComplete(editedText); // Callback to parent to save the edit
    };

    const handleSendPrompt = () => {
        onSendPrompt(prompt); // Callback to send the prompt to the backend
        setPrompt(''); // Clear the input after sending
    };

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Edit Question</DialogTitle>
            <DialogContent>
                {!isEditing ? (
                    <Box>
                        <Typography variant="body1" gutterBottom>{editedText}</Typography>
                        <Button variant="contained" color="primary" onClick={handleEdit} sx={{ mt: 2 }}>
                            Edit
                        </Button>
                    </Box>
                ) : (
                    <Box>
                        <TextField
                            fullWidth
                            label="Edit Question"
                            value={editedText}
                            onChange={(e) => setEditedText(e.target.value)}
                            multiline
                            rows={4}
                            sx={{ mt: 2 }}
                        />
                        <Button variant="contained" color="primary" onClick={handleSaveEdit} sx={{ mt: 2 }}>
                            Save
                        </Button>
                    </Box>
                )}

                <Box mt={4} display="flex" alignItems="center">
                    <TextField
                        fullWidth
                        label="Prompt"
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        variant="outlined"
                    />
                    <IconButton color="primary" onClick={handleSendPrompt} sx={{ ml: 2 }}>
                        <SendIcon />
                    </IconButton>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditQuestionComponent;
