import React, { useState, useEffect } from "react";
import { Box, Card, CardContent, Typography, CardActions, Button, Stack,
    Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import ExploreSettingsComponent from "./ExploreSettingsComponent";


const GeneratedOutputCardComponent = (props) => {
    const [text, setText] = useState();
    const [exploreCategory, setExploreCategory] = useState();
    const [subCategory, setSubCategory] = useState();
    const [isFormOpen, setIsFormOpen] = useState(false); // State for floating form visibility
    const [settings, setSettings] = useState({}); // State for form inputs


    useEffect(() => {
        setText(props.text);
        setExploreCategory(props.exploreCategory);
        setSubCategory(props.subCategory);
    }, [props.text, props.exploreCategory, props.subCategory]);

    // Handle form open/close
    const handleExploreClick = () => {
        setIsFormOpen(true);
    };

    const handleCloseForm = () => {
        setIsFormOpen(false);
    };

    // Handle form submission
    const handleExploreSubmit = async (explorationParams) => {
        try {
            props.onExplore(explorationParams);
            handleCloseForm(); // Close the form after submission
        } catch (error) {
            console.error("Error exploring node:", error);
        }
    };

    const handleAddToWorksheet = () => {
        props.onAddToWorksheet();
    }

    return (
        <Box sx={{ minWidth: 275, display: 'inline-block', margin: 2, maxWidth: '40vw' }}>
            <Card variant="outlined">
                <>
                    <CardContent>
                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                            {exploreCategory}: {subCategory}
                        </Typography>
                        <Typography variant="body2">
                            {text}
                        </Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent: "flex-start", paddingLeft: 2 }}>
                        <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
                            <Button onClick={handleExploreClick}>Explore</Button>
                            <Button>Edit</Button>
                            <Button onClick={handleAddToWorksheet}>Add to worksheet</Button>
                        </Stack>
                    </CardActions>
                </>
            </Card>

            {/* Floating Form */}
            <ExploreSettingsComponent
                isOpen={isFormOpen}
                onClose={handleCloseForm}
                onSubmit={handleExploreSubmit}/>
        </Box>
    );
};

export default GeneratedOutputCardComponent;
