import React, { useState } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import AuthService from "../services/AuthService";
import { useNavigate } from "react-router-dom";
import { auth } from "../services/firebaseConfig"; // Firebase config
import { GoogleAuthProvider, signInWithCredential, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import Divider from '@mui/material/Divider';


const AuthPage = () => {
    const [isSignUp, setIsSignUp] = useState(true); // Toggle between Sign Up and Sign In
    const [role, setRole] = useState("student"); // For Sign Up only
    const [key, setKey] = useState(""); // For Sign Up only
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");

    const navigate = useNavigate();

    const handleGoogleLogin = async (response) => {
        const googleCredential = response.credential;

        try {
            // Use Firebase to authenticate with Google credential
            const credential = GoogleAuthProvider.credential(googleCredential);
            const result = await signInWithCredential(auth, credential);

            // Retrieve Firebase ID token
            const idToken = await result.user.getIdToken();

            if (isSignUp) {
                // Call AuthService for Sign Up
                const registerResponse = await AuthService.registerUser(idToken, role, key);
                setMessage(registerResponse.message);
                navigate("/"); // Redirect to home page
            } else {
                // Call AuthService for Sign In
                const loginResponse = await AuthService.loginUser(idToken);
                console.log(loginResponse);
                setMessage("Successfully signed in!");
                navigate("/"); // Redirect to home page
            }
        } catch (error) {
            setMessage(error.message || "An error occurred. Please try again.");
            console.error(error.message || "An error occurred. Please try again.");
        }
    };

    const handleEmailAuth = async () => {
        try {
            if (isSignUp) {
                await createUserWithEmailAndPassword(auth, email, password);
                setMessage("Account created successfully!");
                navigate("/"); // Redirect to home page
            } else {
                await signInWithEmailAndPassword(auth, email, password);
                setMessage("Successfully signed in!");
                navigate("/"); // Redirect to home page
            }
        } catch (error) {
            setMessage(error.message || "An error occurred. Please try again.");
        }
    };

    return (
        <GoogleOAuthProvider clientId="751204360972-62cq0i38rhqvu9st1i2uap0nuiq1laot.apps.googleusercontent.com">
            <div style={{
                height: "100vh",
                backgroundImage: "url('https://live.staticflickr.com/2924/14018630495_1dbcf9aa13_b.jpg')",
                backgroundSize: "cover",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <div style={{
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    padding: "40px",
                    borderRadius: "10px",
                    maxWidth: "400px",
                    width: "100%",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    textAlign: "center",
                }}>
                    <img src="/favicon.png" alt="Logo" style={{ width: "80px", height: "80px", marginBottom: "15px" }} />
                    <h1>{isSignUp ? "Create an Account" : "Sign In"}</h1>

                    {isSignUp && (
                        <div style={{ marginBottom: "15px" }}>
                            <label>
                                <input
                                    type="radio"
                                    value="teacher"
                                    checked={role === "teacher"}
                                    onChange={() => setRole("teacher")}
                                />
                                Teacher
                            </label>
                            <label style={{ marginLeft: "15px" }}>
                                <input
                                    type="radio"
                                    value="student"
                                    checked={role === "student"}
                                    onChange={() => setRole("student")}
                                />
                                Student
                            </label>
                        </div>
                    )}

                    <div style={{ marginBottom: "15px" }}>
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={{ width: "100%", padding: "10px", marginBottom: "10px", borderRadius: "5px", border: "1px solid #ccc" }}
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            style={{ width: "100%", padding: "10px", borderRadius: "5px", border: "1px solid #ccc" }}
                        />
                    </div>

                    {isSignUp && (
                        <div style={{ marginBottom: "15px" }}>
                            <input
                                type="text"
                                placeholder="Key (for Sign Up only)"
                                value={key}
                                onChange={(e) => setKey(e.target.value)}
                                style={{ width: "100%", padding: "10px", borderRadius: "5px", border: "1px solid #ccc" }}
                            />
                        </div>
                    )}

                    <button
                        onClick={handleEmailAuth}
                        style={{
                            width: "100%",
                            backgroundColor: "#007BFF",
                            color: "white",
                            padding: "10px",
                            borderRadius: "5px",
                            border: "none",
                            cursor: "pointer",
                            marginBottom: "15px",
                        }}
                    >
                        {isSignUp ? "Sign Up with Email" : "Sign In with Email"}
                    </button>

                    <div style={{ margin: "15px 0" }}>
                        <Divider>OR</Divider>
                    </div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <GoogleLogin
                            onSuccess={handleGoogleLogin}
                            onError={() => setMessage("Google Login failed. Please try again.")}
                            text="Continue with Google"
                            style={{
                                width: "100%",
                                padding: "10px",
                                borderRadius: "5px",
                                border: "1px solid #ccc",
                            }}
                        />
                    </div>

                    <p style={{ marginTop: "20px" }}>
                        {isSignUp ? "Already have an account? " : "Don't have an account? "}
                        <span
                            onClick={() => setIsSignUp(!isSignUp)}
                            style={{ color: "#007BFF", cursor: "pointer" }}
                        >
                            {isSignUp ? "Sign In" : "Create an Account"}
                        </span>
                    </p>

                    {message && <p style={{ marginTop: "10px", color: "red" }}>{message}</p>}
                </div>
            </div>
        </GoogleOAuthProvider>
    );
};

export default AuthPage;
