import React, { useState } from 'react';
import { Card, CardContent, IconButton, Typography, Box, Stack } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GeneratedOutputCardComponent from "./GeneratedOutputCardComponent";
import QuestionSettingsCardComponent from "./QuestionSettingsCardComponent";
import ApiService from "../../services/ApiService";

const GenerateQuestionComponent = ({onAddToWorksheet}) => {
    const [graph, setGraph] = useState({
        uid: 'parent',
        text: null,
        exploreCategory: "Depth of Knowledge",
        category: null,
        parentPrompt: null,
        children: [] // Empty children array
    });

    const [selectedNode, setSelectedNode] = useState(graph);
    const isBase = selectedNode && selectedNode.uid === 'parent';

    const handleGenerateBase = async (id, requestBody) => {
        try {
            const response = await ApiService.post('/generate-questions-base', requestBody);
            if (response.status === 200) {
                console.log(response);
                setGraph(response.data.graph);
                setSelectedNode(response.data.graph);
            }
        } catch (error) {
            console.error('Error generating base:', error);
        }
    };

    const handleExplore = async (explorationParams, callingNode) => {
        try {
            const requestBody = {
                graph: graph,
                explorationParams: explorationParams,
                selectedNodeUid: callingNode.uid
            };
            console.log(explorationParams);
            const response = await ApiService.post('/generate-question-explore', requestBody);
            if (response.status === 200) {
                console.log(response);
                const updatedGraph = response.data.graph;
                setGraph(updatedGraph);

                // Find the updated version of the calling node in the new graph
                const findNodeByUid = (node, uid) => {
                    if (node.uid === uid) return node;
                    for (const child of node.children || []) {
                        const result = findNodeByUid(child, uid);
                        if (result) return result;
                    }
                    return null;
                };

                const updatedNode = findNodeByUid(updatedGraph, callingNode.uid);
                setSelectedNode(updatedNode);
            }
        } catch (error) {
            console.error('Error exploring node:', error);
        }
    };

    const handleAddToWorksheet = (node) => {
        onAddToWorksheet(node);
    }

    const handleGoBack = () => {
        const findParentNode = (node, targetUid) => {
            for (const child of node.children || []) {
                if (child.uid === targetUid) return node;
                const result = findParentNode(child, targetUid);
                if (result) return result;
            }
            return null;
        };

        if (!isBase) {
            const parentNode = findParentNode(graph, selectedNode.uid);
            if (parentNode) setSelectedNode(parentNode);
        }
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="flex-start" minHeight="100vh" bgcolor="#f5f5f5">
            <Card sx={{ width: '100%', padding: 2 }}>
                <CardContent>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <IconButton onClick={handleGoBack} disabled={isBase}>
                            <ArrowBackIcon color={isBase ? 'disabled' : 'inherit'} />
                        </IconButton>
                        <Typography variant="h5">
                            {isBase ? 'Base Node' : selectedNode.text || 'Selected Node'}
                        </Typography>
                    </Stack>

                    <Box mt={4}>
                        {isBase ? (
                            <QuestionSettingsCardComponent id={'settingsCard'} onGenerate={handleGenerateBase} />
                        ) : (
                            <GeneratedOutputCardComponent
                                key={selectedNode.uid}
                                text={selectedNode.text}
                                exploreCategory={selectedNode.exploreCategory}
                                subCategory={selectedNode.subCategory}
                            />
                        )}
                    </Box>

                    <Box mt={4}>
                        {selectedNode.children.map((child) => (
                            <GeneratedOutputCardComponent
                                key={child.uid}
                                text={child.text}
                                exploreCategory={child.exploreCategory}
                                subCategory={child.subCategory}
                                parentPrompt={child.parentPrompt}
                                onExplore={(explorationParams) => handleExplore(explorationParams, child)}
                                onAddToWorksheet={() => handleAddToWorksheet(child)}
                            />
                        ))}
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default GenerateQuestionComponent;
