import ApiService from "./ApiService";
import { getAuth, signOut } from "firebase/auth";

const AuthService = {
    /**
     * Registers a new user and stores user session data.
     * @param {string} credential - Firebase ID token from Google Login.
     * @param {string} role - The role selected by the user ("teacher" or "student").
     * @param {string} key - The key provided by the user for verification.
     * @returns {Promise<Object>} - The backend response indicating success or failure.
     */
    async registerUser(credential, role, key) {
        try {
            const response = await ApiService.post("/signup", {
                idToken: credential,
                role,
                additionalData: { key },
            });

            const { uid, email, name, role: userRole } = response.data.user;

            // Save user session details
            this.saveSession(credential, response.data.refreshToken, {
                uid,
                email,
                name,
                role: userRole,
            });

            return response.data; // Backend response
        } catch (error) {
            throw new Error(error.response?.data?.message || "Registration failed.");
        }
    },

    /**
     * Logs in a user and stores user session data.
     * @param {string} credential - Firebase ID token from Google Login.
     * @returns {Promise<Object>} - The backend response with user details.
     */
    async loginUser(credential) {
        try {
            const response = await ApiService.post("/login", {
                idToken: credential,
            });

            const { uid, email, name, role, refreshToken } = response.data.user;

            // Save user session details
            this.saveSession(credential, refreshToken, { uid, email, name, role });

            return response.data; // Backend response
        } catch (error) {
            throw new Error(error.response?.data?.message || "Login failed.");
        }
    },

    /**
     * Logs out the current user and clears all session data.
     * @returns {Promise<void>} - Resolves when the user is successfully logged out.
     */
    async logoutUser() {
        try {
            const auth = getAuth();
            await signOut(auth); // Firebase sign-out
            this.clearSession(); // Clear local storage
            return { message: "Successfully signed out" };
        } catch (error) {
            throw new Error("Failed to sign out. Please try again.");
        }
    },

    /**
     * Saves the user session to localStorage.
     * @param {string} idToken - Firebase ID token.
     * @param {string} refreshToken - Firebase refresh token.
     * @param {Object} user - User details (uid, email, name, role).
     */
    saveSession(idToken, refreshToken, user) {
        localStorage.setItem("idToken", idToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("signInTime", Date.now().toString());
    },

    /**
     * Clears all session data from localStorage.
     */
    clearSession() {
        localStorage.removeItem("idToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("user");
        localStorage.removeItem("signInTime");
    },

    /**
     * Retrieves the current user's session data from localStorage.
     * @returns {Object|null} - User session data or null if not found.
     */
    getSession() {
        const idToken = localStorage.getItem("idToken");
        const user = localStorage.getItem("user");

        try {
            return idToken && user
                ? { idToken, user: JSON.parse(user) }
                : null;
        } catch (error) {
            console.error("Failed to parse user session:", error);
            return null;
        }
    },
};

export default AuthService;
