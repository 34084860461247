import React, { useState, useRef } from 'react';
import { Box, Tabs, Tab, Button } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import GenerateQuestionComponent from "../components/UI/GenerateQuestionComponent";
import WorksheetManagerComponent from "../components/UI/WorksheetManagerComponent";
import RenderWorksheetComponent from "../components/UI/RenderWorksheetComponent";
import ApiService from "../services/ApiService";

const CustomTabPanel = ({ children, value, index }) => {
    return (
        <div
            role="tabpanel"
            style={{
                height: '100%',
                display: value === index ? 'block' : 'none' // Hide inactive tabs without unmounting
            }}
        >
            {children}
        </div>
    );
};

const GenerateWorksheetPage = () => {
    const [tabValue, setTabValue] = useState(0);
    const [worksheetQuestions, setWorksheetQuestions] = useState([]);
    const renderWorksheetRef = useRef(null); // Ref for RenderWorksheetComponent

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleAddToWorksheet = (node) => {
        const newQuestion = {
            uid: uuidv4(), // Generate a unique ID for each question
            text: node.text,
            spacing: 1,
            media: null,
        };
        setWorksheetQuestions([...worksheetQuestions, newQuestion]);
        console.log("Added to worksheet:", newQuestion);
    };

    const handleSetWorksheetQuestions = (questions) => {
        setWorksheetQuestions(questions);
    };

    const handleDownloadPDF = () => {
        if (renderWorksheetRef.current) {
            renderWorksheetRef.current.downloadPDF(); // Call the downloadPDF method exposed by RenderWorksheetComponent
        } else {
            console.error("RenderWorksheetComponent reference is null.");
        }
    };

    const handleSaveWorksheet = () => {
        ApiService.post('/save-worksheet', { questions: worksheetQuestions })
            .then(() => console.log("Worksheet saved successfully"))
            .catch((error) => console.error("Error saving worksheet:", error));
    };

    return (
        <Box display="flex" height="100vh" bgcolor="#f5f5f5" overflow="hidden">
            {/* Left Side - Tabs with Components */}
            <Box
                width="50%"
                display="flex"
                flexDirection="column"
                borderRight={1}
                borderColor="divider"
                sx={{ height: '100vh', overflowY: 'auto' }}
            >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="Question Generator" />
                        <Tab label="Worksheet Manager" />
                    </Tabs>
                </Box>

                <Box sx={{ flexGrow: 1 }}>
                    <CustomTabPanel value={tabValue} index={0}>
                        <GenerateQuestionComponent onAddToWorksheet={handleAddToWorksheet} />
                    </CustomTabPanel>

                    <CustomTabPanel value={tabValue} index={1}>
                        <WorksheetManagerComponent
                            worksheetQuestions={worksheetQuestions}
                            setWorksheetQuestions={handleSetWorksheetQuestions}
                        />
                    </CustomTabPanel>
                </Box>
            </Box>

            {/* Right Side - Render Worksheet */}
            <Box
                width="50%"
                display="flex"
                flexDirection="column"
                borderRight={1}
                borderColor="divider"
                sx={{ height: '100vh', overflowY: 'auto' }}
            >
                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingX: 1 }}>
                    <Tabs value={0} aria-label="basic tabs example">
                        <Tab label="PDF Viewer" />
                    </Tabs>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button variant="contained" color="primary" onClick={handleDownloadPDF}>
                            Download
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleSaveWorksheet}>
                            Save Worksheet
                        </Button>
                    </Box>
                </Box>
                <RenderWorksheetComponent
                    ref={renderWorksheetRef}
                    worksheetQuestions={worksheetQuestions} />
            </Box>
        </Box>
    );
};

export default GenerateWorksheetPage;
