import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAFknB6Akz6LPr9ZdoAw0c6pRZobdtauFE",
    authDomain: "beaver-7d987.firebaseapp.com",
    projectId: "beaver-7d987",
    storageBucket: "beaver-7d987.firebasestorage.app",
    messagingSenderId: "751204360972",
    appId: "1:751204360972:web:9f4fde1372512df1541f29",
    measurementId: "G-BD572J59QK"
};

// Initialize Firebase App
const app = initializeApp(firebaseConfig);

// Export Firebase services
export const auth = getAuth(app);
export const db = getFirestore(app);
export default app;
