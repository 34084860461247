import axios from 'axios';

// Create an instance of axios with default configuration
const ApiService = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http://localhost:5000/api', // Base URL for your API
    timeout: 400000, // Set a timeout for the request (optional)
    headers: {
        'Content-Type': 'application/json',
        // You can add other default headers here
    },
});

// You can also add request interceptors if needed
ApiService.interceptors.request.use(
    (config) => {
        // Perform actions before the request is sent, like adding an auth token
        const token = localStorage.getItem('idToken');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        // Handle errors before request is sent
        return Promise.reject(error);
    }
);

// You can add response interceptors as well
ApiService.interceptors.response.use(
    (response) => {
        // Perform actions on successful response
        return response;
    },
    (error) => {
        // Handle errors
        if (error.response && error.response.status === 401) {
            // Handle unauthorized access (e.g., redirect to login)
        }
        return Promise.reject(error);
    }
);

export default ApiService;
