import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemText, Box, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import AuthService from "../../services/AuthService";

const NAVBAR_HEIGHT = 64; // Define a constant for Navbar height

const Navbar = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const navigate = useNavigate();

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const handleNavigate = (path) => {
        navigate(path);
        setDrawerOpen(false);
    };

    const handleLogout = async () => {
        try {
            await AuthService.logoutUser();
            navigate('/auth');
        } catch (error) {
            console.error("Logout failed:", error);
        }
    };

    return (
        <>
            <AppBar position="fixed" sx={{ height: NAVBAR_HEIGHT }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box display="flex" alignItems="center" sx={{ ml: 1, flexGrow: 1 }}>
                        <Box
                            component="img"
                            src="/favicon.png"
                            alt="Favicon"
                            sx={{ width: 24, height: 24, mr: 1 }}
                        />
                        <Typography variant="h6" sx={{ textAlign: 'left' }}>
                            Beaver Learn
                        </Typography>
                    </Box>
                    <Button
                        color="inherit"
                        onClick={handleLogout}
                        sx={{ textTransform: 'none' }}
                    >
                        Logout
                    </Button>
                </Toolbar>
            </AppBar>
            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
            >
                <List sx={{ width: 250 }}>
                    <ListItem button onClick={() => handleNavigate('/')}>
                        <ListItemText primary="Home" />
                    </ListItem>
                    <ListItem button onClick={() => handleNavigate('/generate-worksheet')}>
                        <ListItemText primary="Generate Worksheet" />
                    </ListItem>
                    <ListItem button onClick={() => handleNavigate('/settings')}>
                        <ListItemText primary="Settings" />
                    </ListItem>
                    <ListItem button onClick={() => handleNavigate('/auth')}>
                        <ListItemText primary="Auth" />
                    </ListItem>
                </List>
            </Drawer>
        </>
    );
};

export { Navbar, NAVBAR_HEIGHT };
