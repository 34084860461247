import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import AuthPage from "./pages/AuthPage";
import AuthService from "./services/AuthService";
import GenerateWorksheetPage from "./pages/GenerateWorksheetPage";
import { Navbar, NAVBAR_HEIGHT } from "./components/UI/Navbar";

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const checkAuthStatus = async () => {
            const session = AuthService.getSession();
            setIsAuthenticated(!!session);
        };

        checkAuthStatus();
    }, []);

    const ProtectedRoute = ({ children }) => {
        return isAuthenticated ? children : <Navigate to="/auth" />;
    };

    return (
        <Router>
            <AppContent isAuthenticated={isAuthenticated} />
        </Router>
    );
};

const AppContent = ({ isAuthenticated }) => {
    const location = useLocation();
    const hideNavbarOnPaths = ["/auth"];
    const isNavbarVisible = isAuthenticated && !hideNavbarOnPaths.includes(location.pathname);

    return (
        <>
            {isNavbarVisible && <Navbar />}
            <div
                style={{
                    marginTop: isNavbarVisible ? NAVBAR_HEIGHT : 0,
                }}
            >
                <Routes>
                    <Route path="/auth" element={<AuthPage />} />
                    <Route
                        path="/"
                        element={
                            <ProtectedRoute isAuthenticated={isAuthenticated}>
                                <GenerateWorksheetPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route path="*" element={<Navigate to="/auth" />} />
                </Routes>
            </div>
        </>
    );
};

const ProtectedRoute = ({ isAuthenticated, children }) => {
    return isAuthenticated ? children : <Navigate to="/auth" />;
};

export default App;
