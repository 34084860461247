import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import { Box, Typography } from '@mui/material';
import './PDFSimulator.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const RenderWorksheetComponent = forwardRef(({ worksheetQuestions }, ref) => {
    const pdfRef = useRef(null);
    const PAGE_HEIGHT = 1123; // Approximate height of A4 in pixels (at 96 DPI)

    const renderPages = () => {
        let currentHeight = 0;
        let pages = [];
        let currentPage = [];

        worksheetQuestions.forEach((question, index) => {
            const questionHeight = measureQuestionHeight(question);

            if (currentHeight + questionHeight > PAGE_HEIGHT) {
                pages.push(currentPage);
                currentPage = [];
                currentHeight = 0;
            }

            currentPage.push(
                <Box
                    key={question.uid}
                    sx={{
                        pageBreakInside: 'avoid',
                        marginBottom: `${question.spacing || 20}px`,
                        padding: 2,
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        {`Question ${index + 1}`}
                    </Typography>
                    <Typography variant="body1">{question.text}</Typography>
                </Box>
            );

            currentHeight += questionHeight;
        });

        if (currentPage.length > 0) {
            pages.push(currentPage);
        }

        return pages;
    };

    const measureQuestionHeight = (question) => {
        const baseHeight = 60;
        const textLength = question.text.length;
        const estimatedHeight = baseHeight + Math.ceil(textLength / 50) * 20;
        return estimatedHeight + (question.spacing || 20);
    };

    const downloadPDFCanvas = async () => {
        const pdfContainer = pdfRef.current;
        if (!pdfContainer) return;

        const canvas = await html2canvas(pdfContainer, {
            scale: 2, // Higher scale for better quality
            useCORS: true,
        });

        const imageData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 210; // A4 width in mm
        const pageHeight = 297; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        let yPosition = 0;
        let pageNumber = 0;

        while (yPosition < imgHeight) {
            if (pageNumber > 0) pdf.addPage(); // Add a new page after the first page
            const yOffset = yPosition * (canvas.height / imgHeight); // Convert mm to px for slicing
            const pageCanvas = document.createElement('canvas');
            const ctx = pageCanvas.getContext('2d');
            pageCanvas.width = canvas.width;
            pageCanvas.height = Math.min(canvas.height - yOffset, pageHeight * (canvas.height / imgHeight));

            ctx.drawImage(
                canvas,
                0,
                yOffset,
                canvas.width,
                pageCanvas.height,
                0,
                0,
                pageCanvas.width,
                pageCanvas.height
            );

            const pageImage = pageCanvas.toDataURL('image/png');
            pdf.addImage(pageImage, 'PNG', 0, 0, imgWidth, Math.min(pageHeight, imgHeight - yPosition));
            yPosition += pageHeight;
            pageNumber++;
        }

        pdf.save('worksheet.pdf');
    };

    const downloadPDF = () => {
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pageWidth = 210; // A4 width in mm
        const margin = 10; // Margin in mm
        const maxWidth = pageWidth - margin * 2; // Width available for text
        const lineHeight = 10; // Height of each line in mm
        const pageHeight = 297; // A4 height in mm

        let currentY = margin;

        worksheetQuestions.forEach((question, index) => {
            // Add question title
            const title = `Question ${index + 1}:`;
            pdf.setFontSize(12);
            pdf.setFont('Helvetica', 'bold');
            const titleHeight = pdf.getTextDimensions(title).h / 2.83464567; // Convert points to mm
            if (currentY + titleHeight > pageHeight - margin) {
                pdf.addPage();
                currentY = margin;
            }
            pdf.text(title, margin, currentY);
            currentY += titleHeight + 2;

            // Add question text
            const questionText = question.text;
            pdf.setFontSize(10);
            pdf.setFont('Helvetica', 'normal');
            const splitText = pdf.splitTextToSize(questionText, maxWidth);
            const textHeight = splitText.length * lineHeight;
            if (currentY + textHeight > pageHeight - margin) {
                pdf.addPage();
                currentY = margin;
            }
            pdf.text(splitText, margin, currentY);
            currentY += textHeight + 5; // Add spacing between questions
        });

        pdf.save('worksheet.pdf');
    };


    // Expose downloadPDF to the parent component
    useImperativeHandle(ref, () => ({
        downloadPDF,
    }));

    const pages = renderPages();

    return (
        <div className="pdf-container">
            <div ref={pdfRef} className="pdf-simulated">
                {pages.map((page, pageIndex) => (
                    <div key={pageIndex} className="page">
                        {page}
                    </div>
                ))}
            </div>
        </div>
    );
});

export default RenderWorksheetComponent;
